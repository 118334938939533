export class ScrollLock {
    public isLocked = false;

    // tslint:disable-next-line: variable-name
    private static _instance: ScrollLock;
    private bodyScrollPosition = 0;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public lock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');
        this.bodyScrollPosition = window.pageYOffset;

        if (webContainer || mobileContainer) {
            document.body.classList.add('bookmark_scroll_lock')
            if (!document.body.classList.contains('parallax') && !document.body.classList.contains('parallax2'))
                document.body.style.setProperty('top', '-' + this.bodyScrollPosition + 'px');
            this.isLocked = true;
        }
    }

    public unlock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');

        if (webContainer || mobileContainer) {
            document.body.classList.remove('bookmark_scroll_lock')
            if (!document.body.classList.contains('parallax') && !document.body.classList.contains('parallax2')) {
                document.body.style.removeProperty('top');
                window.scrollTo(0, this.bodyScrollPosition);
            }
            this.isLocked = false;
        }
    }
}
